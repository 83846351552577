<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style>
    @media all and (max-width: 540px)
    {
        .el-drawer
        {
            width: 50% !important;
        }
    }
</style>
