import Vue from 'vue'
import { 
    Button, 
    container, 
    aside, 
    menu, 
    submenu, 
    table, 
    dropdown, 
    header, 
    footer, 
    MenuItem,
    MenuItemGroup,
    DropdownItem,
    TableColumn,
    DropdownMenu,
    main,
    avatar,
    RadioGroup,
    radio,
    drawer,
    tag,
    breadcrumb,
    BreadcrumbItem,
    FormItem,
    form,
    input,
    DatePicker,
    pagination,
    row,
    col,
    timeline,
    card,
    TimelineItem,
    tabs,
    TabPane,
    Switch,
    empty,
    image,
    checkbox,
    tooltip,
    upload,
    dialog,
    select,
    option,
    TimePicker,
    CheckboxGroup
} from 'element-ui'

Vue.use(Button)
    .use(container)
    .use(aside)
    .use(menu)
    .use(submenu)
    .use(table)
    .use(dropdown)
    .use(header)
    .use(footer)
    .use(MenuItem)
    .use(MenuItemGroup)
    .use(DropdownItem)
    .use(TableColumn)
    .use(DropdownMenu)
    .use(main)
    .use(avatar)
    .use(RadioGroup)
    .use(radio)
    .use(drawer)
    .use(tag)
    .use(breadcrumb)
    .use(BreadcrumbItem)
    .use(FormItem)
    .use(form)
    .use(input)
    .use(DatePicker)
    .use(pagination)
    .use(row)
    .use(col)
    .use(timeline)
    .use(card)
    .use(TimelineItem)
    .use(tabs)
    .use(TabPane)
    .use(Switch)
    .use(empty)
    .use(image)
    .use(checkbox)
    .use(tooltip)
    .use(upload)
    .use(dialog)
    .use(select)
    .use(option)
    .use(TimePicker)
    .use(CheckboxGroup)