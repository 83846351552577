import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/element.js";
import i18n from "@/locale/index";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css"; // 导入样式
import locale from "element-ui/lib/locale/lang/en"; // 导入 Element UI 的英语语言包
import "@/assets/global.css"; //导入全局样式
// import VModal from 'vue-js-modal';
//防抖与节流----防止用户发神经点出bug
import _ from "lodash";
Vue.prototype._ = _;

// 引入echarts----图表
import * as echarts from "echarts";
Vue.prototype.$echarts = echarts;

//图表中某些烦人的小警告 PS:Element ui和echartsm，你们两个库调preventDefault为什么要报警告给我看???
// npm i default-passive-events -S
import "default-passive-events"; //启动后，操作剪切伸缩图片时会有红色报错，不影响使用，关闭则会有警告

//引入session,cookie----保存用户数据
import VueCookies from "vue-cookies";
import VueSession from "vue-session";
Vue.use(VueSession);
Vue.use(VueCookies);
Vue.use(ElementUI, { locale });
// Vue.use(VModal);
import axios from "axios";
import VueAxios from "vue-axios";

Vue.use(VueAxios, axios);

// 分页组件
import Pagination from "@/components/Pagination";
Vue.component("Pagination", Pagination);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  //全局事件总线，兄弟间互传数据
  beforeCreate() {
    Vue.prototype.$bus = this;
  },
}).$mount("#app");
