import i18n from "@/locale/index";
export default {
  //使命名可使用
  namespaced: true,
  //数据
  state: {
    menuData: [
      {
        path: "/person/inform",
        label: i18n.t("Menu.PersNotif"),
        name: "个人通知",
        icon: "bell",
        position: "2",
      },
      {
        path: "/",
        label: i18n.t("Menu.Trd"),
        icon: "coin",
        position: "3",
        children: [
          {
            path: "/manage/discuss",
            name: "discuss",
            label: i18n.t("Menu.Mkt"),
            url: "/manage/discuss",
            position: "3-1",
          },
          {
            path: "/manage/currenciesList",
            name: "currenciesList",
            label: i18n.t("Menu.CurrMgmt"),
            url: "/manage/currenciesList",
            position: "3-2",
          },
          {
            path: "/manage/minerOrder",
            name: "minerOrder",
            label: i18n.t("Menu.miningOrder"),
            url: "/manage/minerOrder",
            position: "3-3",
          },
          {
            path: "/manage/deal",
            name: "deal",
            label: i18n.t("Menu.tradeMgmt"),
            url: "/manage/deal",
            position: "3-4",
          },
        ],
      },

      {
        path: "/",
        label: i18n.t("Menu.userMgmt"),
        icon: "user",
        position: "4",
        children: [
          {
            path: "/manage/user",
            name: "userManage",
            label: i18n.t("Menu.userMgmt"),
            url: "/manage/User",
            position: "4-1",
          },
          {
            path: "/manage/admin",
            name: "adminManage",
            label: i18n.t("Menu.adminMgmt"),
            url: "/manage/admin",
            position: "4-2",
          },
        ],
      },

      {
        path: "/",
        label: i18n.t("Menu.newsMgmt"),
        icon: "message",
        position: "6",
        children: [
          {
            path: "/Image",
            name: "Image",
            label: i18n.t("Menu.uploadImg"),
            url: "/manage/User",
            position: "6-1",
          },
          {
            path: "/location",
            name: "location",
            label: i18n.t("Menu.uploadLocMgmt"),
            url: "/manage/admin",
            position: "6-2",
          },
        ],
      },

      {
        path: "/",
        label: i18n.t("Menu.financeMgmt"),
        icon: "bank-card",
        position: "9",
        children: [
          {
            path: "/manage/topUp",
            name: "topUp",
            label: i18n.t("Menu.DepMgmt"),
            url: "/manage/topUp",
            position: "9-1",
          },
          {
            path: "/manage/withdraw",
            name: "withdraw",
            label: i18n.t("Menu.WdrMgmt"),
            url: "/manage/withdraw",
            position: "9-2",
          },
          {
            path: "/Address/topUpAddress",
            name: "topupAddress",
            label: i18n.t("Menu.rechargeAddrMgmt"),
            url: "/Address/topUpAddress",
            position: "9-3",
          },
          {
            path: "/Address/withdraw",
            name: "withdrawAdd",
            label: i18n.t("Menu.withdrawAddrMgmt"),
            url: "/Address/withdraw",
            position: "9-4",
          },
          {
            path: "/Address/withdrawAddress",
            name: "withdrawAddress",
            label: i18n.t("Menu.assetMgmt"),
            url: "/Address/withdrawAddress",
            position: "9-5",
          },
          {
            path: "/Address/bank",
            name: "bank",
            label: i18n.t("Menu.bankCardMgmt"),
            url: "/Address/bank",
            position: "9-6",
          },
          {
            path: "/manage/FundRecord",
            name: "FundRecord",
            label: i18n.t("Menu.fundRecord"),
            url: "/manage/FundRecord",
            position: "9-7",
          },
        ],
      },

      {
        path: "/person/addannouncement",
        label: i18n.t("Menu.noticeMgmt"),
        name: "公告管理",
        icon: "chat-line-square",
        position: "7",
      },
      {
        path: "/manage/management",
        label: i18n.t("Menu.dataMgmt"),
        name: "管理数据",
        icon: "set-up",
        position: "8",
      },
      {
        path: "/manage/verification",
        label: i18n.t("Menu.identityVerif"),
        name: "身份验证",
        icon: "postcard",
        position: "10",
      },
      {
        path: "/manage/checkShop",
        label: i18n.t("Menu.MinMgmt"),
        name: "矿机管理",
        icon: "monitor",
        position: "11",
      },

      {
        path: "/",
        label: i18n.t("Menu.langMgmt"),
        icon: "price-tag",
        position: "12",
        children: [
          {
            path: "/multilingual/nation",
            name: "main",
            label: i18n.t("Menu.countryMgmt"),
            url: "/multilingual/nation",
            position: "12-1",
          },
          {
            path: "/multilingual/label",
            name: "label",
            label: i18n.t("Menu.tagSetting"),
            url: "/multilingual/label",
            position: "12-2",
          },
          {
            path: "/multilingual/reminder",
            name: "reminder",
            label: i18n.t("Menu.langTipMgmt"),
            url: "/multilingual/reminder",
            position: "12-3",
          },
        ],
      },
      /*
                  有页面没有使用
                {
            path: "",
            name: "verification",
            label: "",
            url: "/manage/verification",
            position: "4-3",
          },
      

                {
            path: "/person/announcement",
            name: "announcement",
            label: "公告中心",
            url: "/person/announcement",
            position: "5-2",
          },
      */
    ],
    isCollapse: false,
  },
  //逻辑
  mutations: {
    EidtIsCollapse(state) {
      state.isCollapse = !state.isCollapse;
    },
    /**
     * Menu刷新定位问题
     * @param {*} state
     * @param {*} val
     */
    EditPosition(state, val) {
      //临时保存页面定位，刷新时定位到刷新前的Menu,
      sessionStorage.setItem("MenuPosition", this.$route.position);
    },
    // EditTempMenuPositon(state,val)
    // {
    //     console.log(val);
    // }
  },
  // 操作数据
  actions: {},
};
