<template>
  <div>
    <!-- PC菜单 -->
    <el-menu
      class="el-menu-vertical-demo"
      :collapse="isCollapse"
      :default-active="defaultAative"
      unique-opened
    >
      <!-- <div class="avatar">
                <el-avatar src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
            </div> -->

      <el-menu-item
        v-for="(item, index) in noChildren"
        :key="index"
        :index="item.position"
        @click="interactCrumbs(item)"
      >
        <i :class="`el-icon-${item.icon}`"></i>
        <span slot="title">{{ item.label }}</span>
      </el-menu-item>

      <el-submenu
        v-for="(item, index) in hasChildren"
        :key="`item-${index}`"
        :index="item.position"
      >
        <template slot="title">
          <i :class="`el-icon-${item.icon}`"></i>
          <span slot="title">{{ item.label }}</span>
        </template>
        <el-menu-item-group
          v-for="(itemChild, index) in item.children"
          :key="`itemChild-${index}`"
        >
          <el-menu-item
            :index="itemChild.position"
            @click="interactCrumbs(itemChild, item)"
            >{{ itemChild.label }}</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
    </el-menu>

    <!-- 移动菜单 -->
    <el-drawer
      title="我是标题"
      :visible.sync="drawer"
      direction="ltr"
      :with-header="false"
    >
      <div class="menu">
        <el-menu :default-active="defaultAative">
          <div class="avatar">
            <el-avatar
              src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"
            ></el-avatar>
          </div>

          <el-menu-item
            v-for="item in noChildren"
            :key="item.name"
            :index="item.position"
            @click="interactCrumbs(item)"
          >
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
          </el-menu-item>

          <el-submenu
            v-for="item in hasChildren"
            :key="item.label"
            :index="item.position"
          >
            <template slot="title">
              <i :class="`el-icon-${item.icon}`"></i>
              <span slot="title">{{ item.label }}</span>
            </template>
            <el-menu-item-group
              v-for="itemChild in item.children"
              :key="itemChild.label"
            >
              <el-menu-item
                :index="itemChild.position"
                @click="interactCrumbs(itemChild, item)"
                >{{ itemChild.label }}</el-menu-item
              >
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "Menu",
  computed: {
    ...mapState("Menu", ["menuData", "isCollapse"]),
    ...mapState("HeaderTag", ["temporarMenuTag"]),
    //没有子菜单
    noChildren() {
      return this.menuData.filter((item) => !item.children);
    },
    //有子菜单
    hasChildren() {
      return this.menuData.filter((item) => item.children);
    },
  },
  data() {
    return {
      drawer: false,
      defaultAative: sessionStorage.getItem("MenuPositon"),
    };
  },
  created() {
    /**
     * 刷新记住当前页
     */
    this.coordinationEditCrumbs([this.$route.path, this.menuData]);
  },
  mounted() {
    this.$bus.$on("drawer", (data) => {
      this.drawer = data;
    });
  },
  watch: {
    //监听路由改变MenuPosition
    $route() {
      this.defaultAative = sessionStorage.getItem("MenuPositon");
    },
  },
  methods: {
    /**
     * 面包屑和菜单标签的添加
     */
    interactCrumbs(item, bigItem) {
      //点击菜单跳转添加面包屑数据
      if (bigItem !== undefined) {
        let mid = [bigItem, item];
        //原地刷新不改变Menu
        sessionStorage.setItem("MenuPositon", mid[1].position);
        if (item.path !== this.$route.path) this.addCrumbs(mid);
      } else {
        let mid = [item];
        //原地刷新不改变Menu
        sessionStorage.setItem("MenuPositon", mid[0].position);
        //item.path !== '/main'防止控制台爆红
        // if(item.path !== this.$route.path && item.path !== '/main')
        if (item.path !== this.$route.path) this.addCrumbs(mid);
      }

      //点击菜单添加菜单标签
      if (item.path !== this.$route.path) {
        //跳转
        this.$router.push(item.path);
        this.addMenuTag(item);
        this.reserveCrumbs();
      }
    },
    ...mapMutations("Menu", ["EditPosition"]),
    ...mapMutations("HeaderTag", [
      "addCrumbs",
      "addMenuTag",
      "coordinationEditCrumbs",
    ]),
    /**
     * 页面刷新后面包屑的保留
     */
    reserveCrumbs() {
      // console.log(this.temporarMenuTag);
      // console.log(this.$route.path);
      sessionStorage.setItem("MenuTag", JSON.stringify(this.temporarMenuTag));
    },
  },
};
</script>

<style lang="less" scoped>
.el-menu {
  height: 100%;
  .avatar {
    height: 60px;
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-menu-vertical-demo::-webkit-scrollbar {
  display: none;
}
.menu {
  height: 100%;
  .el-menu {
    display: block;
    height: 100%;
  }
}

@media all and (max-width: 540px) {
  .el-menu {
    display: none;
  }
  .el-drawer__body {
    width: 50%;
  }
}
</style>
