export default {
    //使命名可使用
    namespaced: true,
    //数据
    state: {
        tableData: [{
            date: '2016-05-02',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1518 弄'
        }, {
            date: '2016-05-04',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1517 弄'
        }, {
            date: '2016-05-01',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1519 弄'
        }, {
            date: '2016-05-03',
            name: '王小虎',
            address: '上海市普陀区金沙江路 1516 弄'
        }]
    },
    //逻辑
    mutations: {
        EidtIsCollapse(state,value)
        {   
            console.log(state.isCollapse);
        }
    },
    // 操作数据
    actions: {

    },
}