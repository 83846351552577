export default {
    //使命名可使用
    namespaced: true,
    //公共数据
    state: {
        //窗口大小
        publicWidth:0,
        // 个人信息
        person:{
            AdminName:'CC',
            AdminSign:'有个世界等你来改变，做自己的英雄！',
            AdminEmail:'215***490@qq.com',
        },

        //组件
        assembly: {
            pictureCuttingWidth:'40%'
        },

        //管理
        manage: {
            width:'50%'
        },

        //PC移动端变换的元素
        //Settings中的Menu
        Settings_tabPosition:'left'
    },
    //逻辑
    mutations: {
        /**
         * 更改窗口大小
         * @param {*} state 
         * @param {*} val 
         */
        EidtpublicWidth(state,val)
        {
            state.publicWidth = val
        },
        //个人设置
        EidtSettings_tabPosition(state,val)
        {
            state.Settings_tabPosition = val
        },
        EidtSettings_personMsg(state,val)
        {
            state.person.AdminName = val[0] === '' ? state.person.AdminName : val[0]
            state.person.AdminSign = val[1] === '' ? state.person.AdminSign : val[1]
            state.person.AdminEmail = val[2] === '' ? state.person.AdminEmail : val[2]
            console.log(state.person);
        },

        //组件
        EidtAssesbly(state,val)
        {
            
            if (state.publicWidth <= 1140)
            {
                state.assembly.pictureCuttingWidth = '60%'
                if (state.publicWidth <= 540)
                state.assembly.pictureCuttingWidth = '98%'
            }
            else
                state.assembly.pictureCuttingWidth = '40%'
        },


        //管理
        EidtManage(state, val)
        {
            if (state.publicWidth <= 1140)
            {
                state.manage.width = '70%'
                if (state.publicWidth <= 540)
                state.manage.width = '98%'
            }
            else
                state.manage.width = '50%'
        }
    },
    // 操作数据
    actions: {

    },
}