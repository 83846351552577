<template>
  <el-container>
    <Menu/>
    <el-container>
      <Header/>
      <HeaderTag/>
      <el-main>
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import Menu from '@/components/Menu'
import Header from '@/components/Header'
import HeaderTag from '@/components/HeaderTag'
export default {
  name: 'Home',
  components: {
    Menu,
    Header,
    HeaderTag
  },
  mounted() 
    {
       
    },
    created() 
    {
       
    },
}
</script>

<style lang="less" scoped>
  .el-container
  {
    height: 100vh;
    .el-container
    {
      display: flex;
      flex-direction: column;
      .el-main
      {
        padding: 5px 10px;
      }
    }
  }
</style>