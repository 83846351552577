<template>
  <div
    ref="menuTag"
    class="HeaderTag"
    @contextmenu.prevent="handleRightClick($event)"
  >
    <el-tag
      v-for="(tag, index) in temporarMenuTag"
      :data-index="index"
      :index="index"
      :key="tag.name"
      :closable="tag.path !== '/main' ? true : false"
      size="small"
      :effect="tag.path == $route.path ? 'dark' : 'light'"
      @click="handleOpenTag(tag)"
      @close="handleShutTag(tag, index)"
    >
      {{ tag.label }}
    </el-tag>
    <!-- 右键菜单 -->
    <div
      v-if="contextMenuVisible"
      :style="{ top: contextMenuY + 'px', left: contextMenuX + 'px' }"
      class="context-menu"
    >
      <div @click="closeOtherTags(currentIndex)">
        {{ $t("leaveOut.closeOthers") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "HeaderTag",
  data() {
    return {
      contextMenuVisible: false,
      contextMenuX: 0,
      contextMenuY: 0,
      currentIndex: null,
    };
  },
  computed: {
    ...mapState("Menu", ["isCollapse", "menuData", "tempMenuPositon"]),
    ...mapState("HeaderTag", ["temporarMenuTag"]),
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  methods: {
    ...mapMutations("Menu", ["EidtIsCollapse", "EidtDrawer"]),
    ...mapMutations("HeaderTag", [
      "delMenuTag",
      "coordinationEditCrumbs",
      "setReserveCrumbs",
      "closeOtherTagss",
    ]),
    /**
     * 点击Tag跳转,修改面包屑，修改Menu默认位置指向
     */
    handleOpenTag(val) {
      console.log("这个数据是什么", val);
      sessionStorage.setItem("MenuPositon", val.position);
      //有bug,position数据出不来
      if (val.path === "/main") {
        sessionStorage.setItem("MenuPositon", "1");
      }
      if (val.path !== this.$route.path) this.$router.push(val.path);
      this.handleEditCrumbs(this.menuData);
    },
    /**
     * 点击Tag关闭,,修改面包屑，修改Menu默认位置指向
     */
    handleShutTag(item, val) {
      this.delMenuTag([item.path, val]);
      if (item.path === this.$route.path) {
        if (val <= this.temporarMenuTag.length - 1) {
          this.$router.push(this.temporarMenuTag[val].path);
          sessionStorage.setItem(
            "MenuPositon",
            this.temporarMenuTag[val].position
          );
        } else {
          this.$router.push(this.temporarMenuTag[val - 1].path);
          sessionStorage.setItem(
            "MenuPositon",
            this.temporarMenuTag[val - 1].position
          );
        }
      }
      this.handleEditCrumbs(this.menuData); //修改面包屑
      this.reserveCrumbs(); //改Tags

      // 判断Tags数量，最后给到main
      if (this.temporarMenuTag.length - 1 === 0) {
        let mainPosition = "1";
        sessionStorage.setItem("MenuPositon", mainPosition);
      }
    },
    /**
     * 补助面包屑的修改
     */
    handleEditCrumbs(val) {
      this.coordinationEditCrumbs([this.$route.path, val]);
    },
    /**
     * 页面刷新后面包屑的保留
     */
    reserveCrumbs() {
      sessionStorage.setItem("MenuTag", JSON.stringify(this.temporarMenuTag));
    },
    handleRightClick(event) {
      this.contextMenuVisible = true;
      this.contextMenuX = event.clientX;
      this.contextMenuY = event.clientY + 10;
      // 获取当前点击的标签索引
      if (event.target.matches(".el-tag")) {
        this.currentIndex = Number(event.target.getAttribute("data-index"));
      }
    },
    closeOtherTags() {
      this.handleOpenTag(this.temporarMenuTag[this.currentIndex]);
      // 过滤保留当前激活的标签和主标签
      this.closeOtherTagss(this.currentIndex);
      this.contextMenuVisible = false;
    },
    handleClickOutside(event) {
      if (!this.$refs.menu) {
        this.contextMenuVisible = false;
      }
    },
  },
  created() {
    this.setReserveCrumbs();
  },
};
</script>

<style lang="less" scoped>
.HeaderTag {
  padding: 6px;
  padding-left: 0;
  .el-tag:nth-child(n + 1) {
    margin-left: 10px;
  }
}

.context-menu {
  position: fixed;
  z-index: 1000;
  background: #40a0ff80;
  color: white;
  border: 1px solid #ccccccda;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 15px;
}
.context-menu div:hover {
  background-color: rgba(255, 255, 255, 0.658);
}

@media all and (max-width: 540px) {
  .HeaderTag {
    display: none;
  }
}
</style>
