import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";

Vue.use(VueRouter);

const routes = [
  //设置默认首页
  {
    path: "/",
    redirect: "home",
  },
  {
    path: "/home",
    name: "home",
    component: Home,
    meta: { requiresAuth: true },
    children: [
      {
        path: "/main", //首页
        name: "main",
        component: () => import("@/views/Main"),
      },
      //组件
      {
        path: "/Assembly/editor", //富文本
        name: "editor",
        component: () => import("@/views/Assembly/Editor"),
      },
      {
        path: "/Assembly/UploadPictures", //上传
        name: "UploadPictures",
        component: () => import("@/views/Assembly/UploadPictures"),
      },
      //多语言
      {
        path: "/multilingual/nation", //国家管理
        name: "nation",
        component: () => import("@/views/multilingual/nation"),
      },
      {
        path: "/multilingual/label", //标签设置
        name: "label",
        component: () => import("@/views/multilingual/label"),
      },
      {
        path: "/multilingual/reminder", //多语言提示
        name: "reminder",
        component: () => import("@/views/multilingual/reminder"),
      },
      //管理
      {
        path: "/manage/user", //用户
        name: "manageUser",
        component: () => import("@/views/Manage/User"),
      },
      {
        path: "/manage/admin", //管理员
        name: "manageAdmin",
        component: () => import("@/views/Manage/Admin"),
      },
      {
        path: "/manage/verification", //身份验证
        name: "verification",
        component: () => import("@/views/Manage/verification"),
      },
      {
        path: "/manage/management", //管理数据
        name: "management",
        component: () => import("@/views/Manage/management"),
      },
      {
        path: "/manage/discuss", //点评
        name: "manageDiscuss",
        component: () => import("@/views/Manage/Discuss"),
      },
      {
        path: "/manage/checkShop", //矿机管理
        name: "managecheckShopManage",
        component: () => import("@/views/Manage/CheckShop"),
      },
      {
        path: "/manage/minerOrder", //矿机订单管理
        name: "minerOrder",
        component: () => import("@/views/Manage/minerOrder"),
      },
      {
        path: "/manage/topUp", //充值管理
        name: "topUp",
        component: () => import("@/views/Manage/topUp"),
      },
      {
        path: "/Address/topUpAddress", //充值地址管理
        name: "topupAddress",
        component: () => import("@/views/Address/topUpAddress"),
      },
      {
        path: "/manage/withdraw", //提现管理
        name: "withdraw",
        component: () => import("@/views/Manage/withdraw"),
      },
      {
        path: "/Address/withdraw", //提现地址管理
        name: "withdrawAdd",
        component: () => import("@/views/Address/withdraw"),
      },
      {
        path: "/Address/withdrawAddress", //资产管理
        name: "withdrawAddress",
        component: () => import("@/views/Address/withdrawAddress"),
      },
      {
        path: "/Address/bank", //银行卡管理
        name: "bank",
        component: () => import("@/views/Address/bank"),
      },
      {
        path: "/manage/deal", //交易管理
        name: "deal",
        component: () => import("@/views/Manage/deal"),
      },
      {
        path: "/manage/FundRecord", //资金流水记录
        name: "FundRecord",
        component: () => import("@/views/Manage/FundRecord"),
      },
      //异常
      {
        path: "/403",
        name: "403",
        component: () => import("@/views/Abnormal/403"),
      },
      {
        path: "/404",
        name: "404",
        component: () => import("@/views/Abnormal/404"),
      },
      {
        path: "/500",
        name: "500",
        component: () => import("@/views/Abnormal/500"),
      },

      //个人
      {
        path: "/person/inform", //个人通知
        name: "inform",
        component: () => import("@/views/Person/inform"),
      },
      {
        path: "/person/announcement", //公告中心
        name: "announcement",
        component: () => import("@/views/Person/announcement"),
      },
      {
        path: "/person/addannouncement", //添加公告
        name: "addannouncement",
        component: () => import("@/views/Person/addannouncemen"),
      },
      {
        path: "/person/settings", //个人设置
        name: "settings",
        component: () => import("@/views/Person/Settings"),
      },
      {
        path: "/person/center", //个人中心
        name: "center",
        component: () => import("@/views/Person/Center"),
      },
      //其他
      {
        path: "/other",
        name: "other",
        component: () => import("@/views/Other"),
      },
      {
        path: "/page1", //页面1
        name: "page1",
        component: () => import("@/views/Page1"),
      },
      {
        path: "/manage/currenciesList", //货币列表
        name: "currenciesList",
        component: () => import("@/views/Manage/currenciesList/index.vue"),
      },
      {
        path: "/Image", //上传轮播图
        name: "Image",
        component: () => import("@/views/Image/image.vue"),
      },
      {
        path: "/location", //上传位置
        name: "location",
        component: () => import("@/views/location/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/Login.vue"),
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!token) {
      next({ path: "/login" });
    } else {
      next();
    }
  } else {
    next();
  }
});

// 解决vue-router在3.0版本以上重复点报错问题
// 法一:本项目无效
// const VueRouterPush = VueRouter.prototype.push
// VueRouter.prototype.push = function push (to) {
//   return VueRouterPush.call(this, to).catch(err => err)
// }
// 法二:本项目有效
let originPush = VueRouter.prototype.push;
let originReplace = VueRouter.prototype.replace;
//重写push/replace
//第一个参数：告诉原来的push方法，往哪里跳转（传递哪些参数）
//第二个参数：成功的回调
//第三个参数：失败的回调
//call/apply区别
//相同点：都可以调用函数一次，都可以篡改函数的上下文(this）一次‘
//不同点：call/apply传递参数：call传递参数用逗号隔开，apply方法执行，传递数组
// 重写 VueRouter 的 push 方法
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originPush.call(this, location, onResolve, onReject);
  }
  return originPush.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // 处理重复导航错误
      return err;
    }
    // 重新抛出错误
    throw err;
  });
};
VueRouter.prototype.replace = function replace(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originReplace.call(this, location, onResolve, onReject);
  }
  return originReplace.call(this, location).catch((err) => {
    if (VueRouter.isNavigationFailure(err)) {
      // 处理重复导航错误
      return err;
    }
    // 重新抛出错误
    throw err;
  });
};

export default router;
