import Vue from "vue";
import VueI18n from "vue-i18n";
import locale from "element-ui/lib/locale";
import en from "@/locale/en";
import zh from "@/locale/zh";
import hi from "@/locale/hi";

import enLocale from "element-ui/lib/locale/lang/en"; // lang i18n
import zhLocale from "element-ui/lib/locale/lang/zh-CN";
import viLocale from "element-ui/lib/locale/lang/vi";
Vue.use(VueI18n);

const messages = {
  en: {
    ...enLocale,
    ...en,
  }, // 英语
  zh: {
    ...zhLocale,
    ...zh, // 中文
  }, // 中文
  hi: {
    ...viLocale,
    ...hi,
  }, // 越南语
};

const DefaultLanguage =
  localStorage.getItem("language") || "hi" || navigator.language;

const i18n = new VueI18n({
  locale: DefaultLanguage, // 设置默认语言
  messages, // 配置所有语言
});

// const init = Vue.prototype._init;

// Vue.prototype._init = function (options) {
//   init.call(this, {
//     i18n,
//     ...options,
//   });
// };

locale.i18n((key, value) => i18n.t(key, value));

export default i18n;
