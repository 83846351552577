import i18n from "@/locale/index";
export default {
  //使命名可使用
  namespaced: true,
  //数据
  state: {
    temporaryMenu: [],
    temporarMenuTag: [
      {
        path: "/manage/discuss",
        name: "discuss",
        label: i18n.t("Menu.Mkt"),
        icon: "tickets",
        url: "/main",
        position: "2",
      },
    ],
    headImg: "",
  },
  //逻辑
  mutations: {
    /**
     * 添加面包屑
     * @param {*} state
     * @param {*} val 菜单数组集合
     */
    addCrumbs(state, val) {
      state.temporaryMenu = [];
      if (val && val.length > 0) {
        // 通过遍历 val 数组添加所有面包屑项
        val.forEach((item) => {
          state.temporaryMenu.push(item);
        });
      }
    },
    /**
     * 添加菜单标签(Tags)
     * @param {*} state
     * @param {*} val
     */
    addMenuTag(state, val) {
      let res = state.temporarMenuTag.findIndex(
        (item) => item.name === val.name
      );
      if (res === -1) {
        state.temporarMenuTag.push(val);
      }
    },
    /**
     * 删除菜单标签(Tags)
     * @param {*} state
     * @param {*} val
     */
    delMenuTag(state, val) {
      state.temporarMenuTag.splice(val[1], 1);
    },
    /**
     * 关闭Tag修改面包屑  PS:可以考虑和addCrumbs合并封装
     * @param {*} state
     * @param {*} val
     */
    coordinationEditCrumbs(state, val) {
      //分类
      let noChildren = val[1].filter((item) => !item.children);
      let hasChildren = val[1].filter((item) => item.children);

      //找到当前路由
      let tempMenuTag = [];
      noChildren.forEach((element) => {
        if (element.path === val[0] && element.path !== "/main") {
          tempMenuTag.push(element);
        }
      });
      hasChildren.forEach((element) => {
        element.children.forEach((item) => {
          if (item.path === val[0] && item.path !== "/main") {
            tempMenuTag.push(element);
            tempMenuTag.push(item);
          }
        });
      });

      //添加面包屑菜单
      state.temporaryMenu = [];
      if (tempMenuTag.children === undefined)
        state.temporaryMenu.push(...tempMenuTag);
      else state.temporaryMenu.push(...tempMenuTag);
    },
    /**
     * 刷新页面恢复Tag状态
     * @param {*} state
     * @param {*} val
     */
    setReserveCrumbs(state, val) {
      if (sessionStorage.getItem("MenuTag"))
        if (sessionStorage.getItem("MenuTag").length > 0) {
          if (state.temporarMenuTag.length === 1) {
            let temp = JSON.parse(sessionStorage.getItem("MenuTag"));
            state.temporarMenuTag.splice(0, 1);
            state.temporarMenuTag.push(...temp);
          }
        }
    },

    // 关闭除当前选中和主页面标签外的所有标签
    closeOtherTagss(state, currentIndex) {
      state.temporarMenuTag = state.temporarMenuTag.filter((tag, index) => {
        return tag.path === "/main" || index === currentIndex;
      });
      sessionStorage.setItem("MenuTag", JSON.stringify(state.temporarMenuTag));
    },
  },
  // 操作数据
  actions: {},
};
