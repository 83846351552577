<template>
  <el-header style="text-align: right; font-size: 12px">
    <div class="btn-arrow">
      <el-button
        type="primary"
        @click="handleIsCollapse"
        icon="el-icon-s-operation"
        size="mini"
      ></el-button>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <!-- <el-breadcrumb-item v-for="item in temporaryMenu" :key="item.path"> <span @click="handlerPath(item.path)">{{item.label}}</span></el-breadcrumb-item> -->
        <el-breadcrumb-item
          v-for="(item, index) in temporaryMenu"
          :key="item.index"
          >{{ item.label }}</el-breadcrumb-item
        >
      </el-breadcrumb>
    </div>
    <div style="display: flex; align-items: center">
      <div @click="onToggle">
        <img
          :src="isFullscreen ? normalScreenImage : fullscreenImage"
          alt=""
          style="width: 40px; height: 40px; vertical-align: middle"
        />
      </div>
      <el-dropdown class="" style="margin: 0px 20px" trigger="click">
        <img
          src="@/assets/header/en.png"
          alt=""
          style="width: 40px; height: 40px; vertical-align: middle"
        />
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item
            v-for="(item, index) in languageList"
            :key="index"
            @click.native="languageChange(item.value)"
            :style="{ color: item.value === language ? '#409EFF' : '' }"
            >{{ item.name }}</el-dropdown-item
          >
        </el-dropdown-menu>
      </el-dropdown>
      <el-dropdown
        class="avatar-container right-menu-item hover-effect"
        trigger="click"
      >
        <div class="avatar-wrapper">
          <!-- <img :src="url" class="user-avatar" /> -->
          <el-image class="user-avatar" :src="url" fit="cover"></el-image>
          <i class="el-icon-caret-bottom" />
        </div>
        <el-dropdown-menu slot="dropdown">
          <router-link to="/person/center" style="text-decoration: none">
            <el-dropdown-item>{{ $t("index.pcenter") }}</el-dropdown-item>
          </router-link>
          <el-dropdown-item divided @click.native="handleCancel">
            <span>{{ $t("index.Logout") }}</span>
          </el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </el-header>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import screenfull from "screenfull";
import i18n from "@/locale";
export default {
  name: "Header",

  computed: {
    ...mapState("Menu", ["Menu", "isCollapse"], "HeaderTag", ["temporaryMenu"]),
    ...mapState("HeaderTag", ["temporaryMenu"]),
    ...mapState("Public", ["person"]),
  },
  data() {
    return {
      fullscreenImage: require("@/assets/header/fullScreen.png"), //全屏图片
      normalScreenImage: require("@/assets/header/CancelFullScreen.png"), //取消全屏图片
      isFullscreen: false, //判断是否全屏
      screenWidth: null, //屏幕宽度
      url: "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg", //用户头像
      language: localStorage.getItem("language") || navigator.language || "hi", //当前国际化语言
      languageList: [
        {
          name: "Việt nam",
          value: "hi",
        },
        {
          name: "中文",
          value: "zh",
        },
        {
          name: "English",
          value: "en",
        },
      ], //可选语言列表
    };
  },
  methods: {
    ...mapMutations("Menu", ["EidtIsCollapse", "EidtDrawer"]),
    ...mapMutations("Public", [
      "EidtpublicWidth",
      "EidtSettings_tabPosition",
      "EidtAssesbly",
      "EidtManage",
    ]),
    handleIsCollapse() {
      if (document.body.clientWidth > 540) {
        this.EidtIsCollapse();
      }
      if (document.body.clientWidth <= 540) {
        this.$bus.$emit("drawer", true);
      }
    },
    // 退出登录
    handleCancel() {
      this.$session.set("user-pwd-key", undefined);
      this.$router.push("/login");
      sessionStorage.clear();
    },
    // 屏幕放大
    onToggle() {
      //   screenfull.toggle();
      if (screenfull.isEnabled) {
        this.isFullscreen = !this.isFullscreen;
        screenfull.toggle();
      }
    },
    // 语言切换
    languageChange(value) {
      // 显示加载动画
      let loadingInstance = this.$loading({
        lock: true,
        text: "Loading...",
        spinner: "el-icon-loading",
        background: "rgba(128, 128, 128, 0.5)",
      });

      // 延时执行语言切换和页面重载，确保加载动画能够显示
      setTimeout(() => {
        // 更新 Vue I18n 的 locale
        i18n.locale = value;
        // 更新本地变量，如果它在模板或其他地方被引用
        this.language = value;

        // 清除可能存储的相关 session 数据
        sessionStorage.removeItem("MenuTag");

        // 本地化存储新的语言设置
        localStorage.setItem("language", value);

        // 刷新页面以应用新语言
        location.reload();

        // 由于页面会刷新，这里关闭 loading 实例实际上是多余的
        // loadingInstance.close();
      }, 500); // 短延时确保用户能看到加载动画
    },
  },
  watch: {
    screenWidth(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.screenWidth = val;
        this.timer = true;
        let that = this;
        //这里修改所有的PC移动端数据变换，可以考虑放到APP中
        setTimeout(function () {
          that.EidtpublicWidth(that.screenWidth);
          if (that.screenWidth <= 540) {
            that.EidtSettings_tabPosition("top");
          } else {
            that.$bus.$emit("drawer", false);
            that.EidtSettings_tabPosition("left");
          }
          that.EidtAssesbly();
          that.EidtManage();
          // console.log(that.screenWidth)
          that.timer = false;
        }, 500);
      }
    },
  },
  mounted() {
    //实现移动Mneu问题
    //获取屏幕尺寸
    this.screenWidth = document.body.clientWidth;
    window.onresize = () => {
      //屏幕尺寸变化
      return (() => {
        this.screenWidth = document.body.clientWidth;
      })();
    };
  },
};
</script>

<style lang="less" scoped>
.el-header {
  border-bottom: 1px solid #e7e7e7;
  color: #333;
  line-height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  .btn-arrow {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-breadcrumb {
    margin-left: 10px;
  }
}
.right-menu-item {
  display: inline-block;
  padding: 0 8px;
  height: 100%;
  font-size: 18px;
  color: #5a5e66;
  vertical-align: text-bottom;

  &.hover-effect {
    cursor: pointer;
    transition: background 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
.avatar-container {
  margin-right: 30px;

  .avatar-wrapper {
    position: relative;

    .user-avatar {
      cursor: pointer;
      width: 50px;
      height: 50px;
      border-radius: 10px;
      vertical-align: middle;
    }

    .el-icon-caret-bottom {
      cursor: pointer;
      position: absolute;
      right: -20px;
      top: 25px;
      font-size: 12px;
    }
  }
}
@media all and (max-width: 540px) {
  .el-breadcrumb {
    display: none;
  }
}
</style>
