import Vue from 'vue'
import Vuex from 'vuex'
import Menu from './modules/Menu' //菜单列表数据
import Main from './modules/Main' //主页中的数据
import HeaderTag from './modules/HeaderTag' //菜单Tags标签数据
import Public from './modules/Public' //公共数据
import TempData from './modules/TempData'  //临时数据

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    Menu,
    Main,
    HeaderTag,
    Public,
    TempData,
  }
})
